body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  background: #f8f8f8;
  scrollbar-gutter: stable;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
